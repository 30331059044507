import logo from './assets/images/nova-scotia-loyal-logo@2x.png';
import './assets/styles/App.scss';

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 body-col">
            <div className="logo-container">
              <img src={logo} className="logo" alt="Nova Scotia Loyal. Built for Nova Scotians, by Nova Scotians"/>
            </div>
            <h2>What does Nova Scotia Loyal mean?</h2>
            <p>It is time to celebrate everything Nova Scotia has to offer - our food, our culture, our vibrant communities, our creativity, ingenuity and can-do spirit. It is time to support each other in big ways and small.</p>
            <p>But loyalty works both ways. We want everyone, from Cape Breton to Halifax to Yarmouth, to tell us what Nova Scotia Loyal means to them. It lies in our heritage and will extend into our future.</p>
            <p>We’re establishing Nova Scotia Loyal to build on everything great about Nova Scotia - and make things even better.</p>
            <h3>Stay tuned for more!</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
